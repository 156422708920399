import axios from 'axios';
import common from '../jsons/common.json'
import { db } from '../db';

const valueHelper = {

    valueSelect(value, options){
        let selected = null;
        if(options != null){
            if(value != null){
                selected = options.find(x => x.value === value);
            } else {
                selected = options.find(x => x.default === true);
            }
        }
        return selected;
    },

    async getCountries(){
        let raw = await getItem('countries');
        let json = null;
        if(raw == null){
            let res = await axios.get('/api/countryselect');
            await setItem('countries', res.data);
            json = res.data;
        } else {
            json = raw.data;
        }
        return json;
    },

    async getCanadianProvs(){
        let raw = await getItem('canadianProvs');
        let json = null;
        if(raw == null){
            let res = await axios.get('/api/provinceselect?country=' + common.canada);
            await setItem('canadianProvs', res.data);
            json = res.data;
        } else {
            json = raw.data;
        }
        return json;
    },

    async getPermCachedValues(list, tenant){
        let result = {};
        for(let row of list){
            let values = null;
            switch (row){
                case common.cacheValues.editReason:
                    values = await getValues(null, 'editReasons', 'values/' + common.valueTypes.editReason.id, tenant);
                    result.editReasons = values;
                    break;
                default:
                     break;
            }

        }
        return result;
    }, 

    async getEquipmentByMachineType(machineType){
        let raw = await getItem('equipment' + machineType);
        let json = null;
        if(raw == null || raw.length === 0 || raw === '[]'){
            let res = await axios.get('/api/equipmentselect?machineType=' + machineType);
            await setItem('equipment' + machineType, res.data);
            json = res.data;
        } else {
            json = raw.data;
        }
        return json;
    },

    getValueTypeKeyFromId(findId){
        for(let key in common.valueTypes){
            if(common.valueTypes[key].id === findId){
                return common.valueTypes[key].valueName;
            }
        }
    },

    inList: function(type, types){
        let found = types.find(x => x === type);
        return found != null;
    },

    packagedHCCategory(category){
        if(category == null || category === '')
            return false;
        let found = common.packagedHCCategories.find(x => x === category);
        return found != null;
    },

    thcHCCategory(category){
        if(category == null || category === '')
            return false;
        let found = common.thcHCCategories.find(x => x === category);
        return found != null;
    },

    stageItemSet(stage, items, constants){
        let dryIndex = items.findIndex(x => x.value === constants.items.dryPlantWaste);
        if(dryIndex > -1){
            items.splice(dryIndex, 1);
        }
        let exciseIndex = items.findIndex(x => x.value === constants.items.exciseWaste);
        if(exciseIndex > -1){
            items.splice(exciseIndex, 1);
        }
        let hempIndex = items.findIndex(x => x.value === constants.items.hempWaste);
        if(hempIndex > -1){
            items.splice(hempIndex, 1);
        }
        let rootIndex = items.findIndex(x => x.value === constants.items.rootball);
        if(rootIndex > -1){
            if(stage === constants.batchStages.germination || stage === constants.batchStages.prop){
                items.splice(rootIndex, 1);
            }
        }
        let vegIndex = items.findIndex(x => x.value === constants.items.vegPlantWaste);
        if(vegIndex > -1){
            if(stage !== constants.batchStages.prop && stage !== constants.batchStages.veg && stage !== constants.batchStages.mother){
                items.splice(vegIndex, 1);
            }
        }
        let wholeIndex = items.findIndex(x => x.value === constants.items.wholePlantWaste);
        if(wholeIndex > -1){
            if(stage !== constants.batchStages.flower){
                items.splice(wholeIndex, 1);
            }
        }
        let wetIndex = items.findIndex(x => x.value === constants.items.wetPlantWaste);
        if(wetIndex > -1){
            if(stage !== constants.batchStages.harvest && stage !== constants.batchStages.harvestWaste){
                items.splice(wetIndex, 1);
            }
        }
        let seedIndex = items.findIndex(x => x.value === constants.items.seedWaste);
        if(seedIndex > -1){
            if(stage !== constants.batchStages.germination){
                items.splice(seedIndex, 1);
            }
        }
        return items;
    },

    async getStatusesForObject(objectId, tenant){
        let dateRes = await axios.get('/api/tenantupdateddates');
        let dateValues = dateRes.data;
        let dataObj = await getItem(objectId + 'Statuses' + tenant);
        let json = dataObj != null ? dataObj.data : null;

        let cacheDateRaw = dataObj != null ? dataObj.date : null;
        let retrieve = false
        if(json == null){
            retrieve = true;
        }
        if(!retrieve && cacheDateRaw != null){
            retrieve = serverNewer(dateValues.value, cacheDateRaw);
        }
        if(retrieve){
            let res = await axios.get('/api/objectstatuses/' + objectId);
            await setItem(objectId + 'Statuses' + tenant, res.data);
            json = res.data;
        }
        return json;
    },

    async getCachedValues(list, constants, objectId, tenant){
        let dateRes = await axios.get('/api/tenantupdateddates');
        let dateValues = dateRes.data;
        let result = {};
        for(let row of list){
            let values = null;
            switch (row){
                case common.cacheValues.object:
                    values = await getValues(dateValues.object, 'allObjects', 'objectselect', tenant);
                    result.objects = values;
                    break;
                case common.cacheValues.itemLedgerObject:
                    values = await getValues(dateValues.object, 'itemLedgerObjects', 'objectselect?canItemLedger=true', tenant);
                    result.itemLedgerObjects = values;
                    break;
                case common.cacheValues.item:
                    values = await getValues(dateValues.item, 'selectItems', 'availableitems', tenant);
                    result.items = values;
                    break;
                case common.cacheValues.allItems:
                    values = await getValues(dateValues.item, 'allItems', 'allitems', tenant);
                    result.allItems = values;
                    break;
                case common.cacheValues.user:
                    values = await getValues(dateValues.user, 'allUsers', 'allusers', tenant);
                    result.users = values;
                    break;
                case common.cacheValues.manager:
                    values = await getValues(dateValues.user, 'allManagers', 'userlist/managers', tenant);
                    result.managers = values;
                    break;
                case common.cacheValues.qa:
                    values = await getValues(dateValues.user, 'allQas', 'userlist/qas', tenant);
                    result.qas = values;
                    break;
                case common.cacheValues.bin:
                    values= await getValues(dateValues.bin, 'allBins', 'binselect', tenant);
                    result.bins = values;
                    break;
                case common.cacheValues.defaultBin:
                    values= await getValues(dateValues.bin, 'defaultBins', 'binselectdefault', tenant);
                    result.defaultBins = values;
                    break;
                case common.cacheValues.testDefinition:
                    values= await getValues(dateValues.testDefinition, 'testDefinitions', 'testdefinitionselect', tenant);
                    result.testDefinitions = values;
                    break;
                case common.cacheValues.customer:
                    values = await getValues(dateValues.customer, 'allCustomers', 'customerselect', tenant);
                    result.customers = values;
                    break;
                case common.cacheValues.vendor:
                    values = await getValues(dateValues.vendor, 'allVendors', 'vendorselect', tenant);
                    result.vendors = values;
                    break;
                case common.cacheValues.maintenanceVendor:
                    values = await getValues(dateValues.vendor, 'maintenanceVendor', 'vendorselect?type=' + constants.vendorTypes.maintenance, tenant);
                    result.maintenanceVendors = values;
                    break;
                case common.cacheValues.cropTreatmentVendor:
                    values = await getValues(dateValues.vendor, 'cropTreatmentVendor', 'vendorselect?type=' + constants.vendorTypes.cropTreatment, tenant);
                    result.cropTreatmentVendors = values;
                    break;
                case common.cacheValues.shippingVendor:
                    values = await getValues(dateValues.vendor, 'shippingVendor', 'vendorselect?type=' + constants.vendorTypes.shipping, tenant);
                    result.shippingVendors = values;
                    break;
                case common.cacheValues.equipment:
                    values = await getValues(dateValues.equipment, 'equipmentRecords', 'equipmentselect', tenant);
                    result.equipments = values;
                    break;
                case common.cacheValues.equipmentAll:
                    values = await getValues(dateValues.equipment, 'equipmentAllRecords', 'equipmentselect?hide=true', tenant);
                    result.equipmentAll = values;
                    break;
                case common.cacheValues.processingEquipment:
                    values = await getValues(dateValues.equipment, 'processingEquipments', 'equipmentselect', tenant);
                    result.processingEquipments = values;
                    break;
                case common.cacheValues.strain:
                    values = await getValues(dateValues.strain, 'allStrains', 'strainselect', tenant);
                    result.strains = values;
                    break;
                case common.cacheValues.cultivationBatch:
                    values = await getValues(dateValues.cultivationBatch, 'allCultivationBatches', 'ebatchselect', tenant);
                    result.batches = values;
                    break;
                case common.cacheValues.pesticideRecipe:
                    values = await getValues(dateValues.pesticideRecipe, 'allPesticideRecipes', 'pesticiderecipeselect', tenant);
                    result.pesticideRecipes = values;
                    break;
                case common.cacheValues.itemCategory:
                    values = await getValues(dateValues.itemCategory, 'allItemCategories', 'itemcatgoryselect', tenant);
                    result.itemCategories = values;
                    break;
                case common.cacheValues.status:
                    values = await getValues(dateValues.status, 'allStatuses', 'values/' + common.valueTypes.status.id, tenant);
                    result.statuses = values;
                    break;
                case common.cacheValues.hcReportCategory:
                    values = await getValues(dateValues.hcReportCategory, 'allHcReportCategory', 'values/' + common.valueTypes.hcReportCategory.id, tenant);
                    result.hcReportCategories = values;
                    break;
                case common.cacheValues.productionOrderStatus:
                    values = await getValues(dateValues.productionOrderStatus, 'allProductionOrderStatuses', 'values/' + common.valueTypes.productionOrderStatus.id, tenant);
                    result.productionOrderStatuses = values;
                    break;
                case common.cacheValues.routing:
                    values = await getValues(dateValues.routing, 'allRoutings', 'routeselect', tenant);
                    result.routings = values;
                    break;
                case common.cacheValues.routingAll:
                    values = await getValues(dateValues.routing, 'allRoutingsHidden', 'routeselect?all=true', tenant);
                    result.allRoutings = values;
                    break;
                case common.cacheValues.bom:
                    values = await getValues(dateValues.bom, 'allBoms', 'bomselect', tenant);
                    result.boms = values;
                    break;
                case common.cacheValues.bomAll:
                    values = await getValues(dateValues.bom, 'allBomsHidden', 'bomselect?all=true', tenant);
                    result.allBoms = values;
                    break;
                case common.cacheValues.equipmentCalibrationType:
                    values = await getValues(dateValues.equipmentCalibrationType, 'allEquipmentCalibrationTypes', 'equipmentcalibtypeselect', tenant);
                    result.equipmentCalibrationTypes = values;
                    break;
                case common.cacheValues.department:
                    values = await getValues(dateValues.value, 'allDepartments', 'values/' + common.valueTypes.department.id, tenant);
                    result.departments = values;
                    break;
                case common.cacheValues.areaUOM:
                    values = await getValues(dateValues.value, 'allAreaUOMs', 'values/' + common.valueTypes.areaUOM.id, tenant);
                    result.areaUOMs = values;
                    break;
                case common.cacheValues.quantityUOM:
                    values = await getValues(dateValues.value, 'allQuantityUOMs', 'values/' + common.valueTypes.quantityUom.id, tenant);
                    result.quantityUOMs = values;
                    break;
                case common.cacheValues.timeUOM:
                    values = await getValues(dateValues.value, 'allTimeUOMs', 'values/' + common.valueTypes.timeUom.id, tenant);
                    result.timeUOMs = values;
                    break;
                case common.cacheValues.batchStatus:
                    values = await getValues(dateValues.value, 'allBatchStatuses', 'values/' + common.valueTypes.batchStatus.id, tenant);
                    result.batchStatuses = values;
                    break;
                case common.cacheValues.batchStage:
                    values = await getValues(dateValues.value, 'allBatchStages', 'values/' + common.valueTypes.batchStage.id, tenant);
                    result.batchStages = values;
                    break;
                case common.cacheValues.containerType:
                    values = await getValues(dateValues.value, 'allContainerTypes', 'values/' + common.valueTypes.containerType.id, tenant);
                    result.containerTypes = values;
                    break;
                case common.cacheValues.lotStatus:
                    values = await getValues(dateValues.value, 'allLotStatuses', 'values/' + common.valueTypes.lotStatus.id, tenant);
                    result.lotStatuses = values;
                    break;
                case common.cacheValues.containerStatus:
                    values = await getValues(dateValues.value, 'allContainerStatuses', 'values/' + common.valueTypes.containerStatus.id, tenant);
                    result.containerStatuses = values;
                    break;
                case common.cacheValues.productionBatchStatus:
                    values = await getValues(dateValues.value, 'allProductionBatchStatuses', 'values/' + common.valueTypes.productionBatchStatus.id, tenant);
                    result.productionBatchStatuses = values;
                    break;
                case common.cacheValues.pest:
                    values = await getValues(dateValues.value, 'allPests', 'values/' + common.valueTypes.pest.id, tenant);
                    result.pests = values;
                    break;
                case common.cacheValues.roomType:
                    values = await getValues(dateValues.value, 'allRoomtypes', 'values/' + common.valueTypes.roomType.id, tenant);
                    result.roomTypes = values;
                    break;
                case common.cacheValues.lightType:
                    values = await getValues(dateValues.value, 'allLightTypes', 'values/' + common.valueTypes.lightType.id, tenant);
                    result.lightTypes = values;
                    break;
                case common.cacheValues.adjustmentReason:
                    values = await getValues(dateValues.value, 'allAdjustmentReasons', 'values/' + common.valueTypes.adjustmentReason.id, tenant);
                    result.adjustmentReasons = values;
                    break;
                case common.cacheValues.ledgerEntryType:
                    values = await getValues(dateValues.value, 'allLedgerEntryTypes', 'values/' + common.valueTypes.ledgerEntryType.id, tenant);
                    result.ledgerEntryTypes = values;
                    break;
                case common.cacheValues.processingType:
                    values = await getValues(dateValues.value, 'allProcessingType', 'values/' + common.valueTypes.processingType.id, tenant);
                    result.processingTypes = values;
                    break;
                case common.cacheValues.strainType:
                    values = await getValues(dateValues.value, 'allStrainType', 'values/' + common.valueTypes.strainType.id, tenant);
                    result.strainTypes = values;
                    break;
                case common.cacheValues.receiptType:
                    values = await getValues(dateValues.value, 'allReceiptType', 'values/' + common.valueTypes.receiptType.id, tenant);
                    result.receiptTypes = values;
                    break;
                case common.cacheValues.shipmentType:
                    values = await getValues(dateValues.value, 'allShipmentType', 'values/' + common.valueTypes.shipmentType.id, tenant);
                    result.shipmentTypes = values;
                    break;
                case common.cacheValues.scheduleStatus:
                    values = await getValues(dateValues.value, 'allScheduleStatus', 'values/' + common.valueTypes.scheduleStatus.id, tenant);
                    result.scheduleStatuses = values;
                    break;
                case common.cacheValues.binType:
                    values = await getValues(dateValues.value, 'binTypes', 'values/' + common.valueTypes.binType.id, tenant);
                    result.binTypes = values;
                    break;
                case common.cacheValues.cullReason:
                    values = await getValues(dateValues.value, 'allCullReasons', 'values/' + common.valueTypes.cullReason.id, tenant);
                    result.cullReasons = values;
                    break;
                case common.cacheValues.batchTemplateStatus:
                    values = await getValues(dateValues.value, 'allBatchTemplateStatus', 'values/' + common.valueTypes.batchTemplateStatus.id, tenant);
                    result.batchTemplateStatuses = values;
                    break;
                case common.cacheValues.batchStartType:
                    values = await getValues(dateValues.value, 'allBatchStartType', 'values/' + common.valueTypes.batchStartType.id, tenant);
                    result.batchStartTypes = values;
                    break;
                case common.cacheValues.batchType:
                    values = await getValues(dateValues.value, 'allBatchType', 'values/' + common.valueTypes.batchType.id, tenant);
                    result.batchTypes = values;
                    break;
                case common.cacheValues.plantType:
                    values = await getValues(dateValues.value, 'allPlantType', 'values/' + common.valueTypes.plantType.id, tenant);
                    result.plantTypes = values;
                    break;
                case common.cacheValues.regulator:
                    values = await getValues(dateValues.value, 'allRegulators', 'values/' + common.valueTypes.regulators.id, tenant);
                    result.regulators = values;
                    break;
                case common.cacheValues.priority:
                    values = await getValues(dateValues.value, 'allPriorities', 'values/' + common.valueTypes.priority.id, tenant);
                    result.priorities = values;
                    break;
                case common.cacheValues.carType:
                    values = await getValues(dateValues.value, 'allCarTypes', 'values/' + common.valueTypes.carType.id, tenant);
                    result.carTypes = values;
                    break;
                case common.cacheValues.changeReason:
                    values = await getValues(dateValues.value, 'allChangeReasons', 'values/' + common.valueTypes.changeReason.id, tenant);
                    result.changeReasons = values;
                    break;
                case common.cacheValues.applicationMethod:
                    values = await getValues(dateValues.value, 'allApplicationMethods', 'values/' + common.valueTypes.applicationMethod.id, tenant);
                    result.applicationMethods = values;
                    break;
                case common.cacheValues.cropTreatmentType:
                    values = await getValues(dateValues.value, 'allCropTreatmentTypes', 'values/' + common.valueTypes.cropTreatmentType.id, tenant);
                    result.cropTreatmentTypes = values;
                    break;
                case common.cacheValues.cropTreatmentSubType:
                    values = await getValues(dateValues.value, 'allCropTreatmentSubTypes', 'values/' + common.valueTypes.cropTreatmentSubType.id, tenant);
                    result.cropTreatmentSubTypes = values;
                    break;
                case common.cacheValues.curingActivity:
                    values = await getValues(dateValues.value, 'allCuringActivities', 'values/' + common.valueTypes.curingActivity.id, tenant);
                    result.curingActivities = values;
                    break;
                case common.cacheValues.batchDestructionReason:
                    values = await getValues(dateValues.value, 'allBatchDestructionReasions', 'values/' + common.valueTypes.batchDestructionReason.id, tenant);
                    result.batchDestructionReasons = values;
                    break;
                case common.cacheValues.feedingType:
                    values = await getValues(dateValues.value, 'feedingTypes', 'values/' + common.valueTypes.feedingType.id, tenant);
                    result.feedingTypes = values;
                    break;
                case common.cacheValues.trimType:
                    values = await getValues(dateValues.value, 'trimTypes', 'values/' + common.valueTypes.trimType.id, tenant);
                    result.trimTypes = values;
                    break;
                case common.cacheValues.tempUOM:
                    values = await getValues(dateValues.value, 'tempUOMs', 'values/' + common.valueTypes.temperatureUom.id, tenant);
                    result.tempUOMs = values;
                    break;
                case common.cacheValues.dryType:
                    values = await getValues(dateValues.value, 'dryTypes', 'values/' + common.valueTypes.dryingType.id, tenant);
                    result.dryingTypes = values;
                    break;
                case common.cacheValues.routingType:
                    values = await getValues(dateValues.value, 'routingTypes', 'values/' + common.valueTypes.routingType.id, tenant);
                    result.routingTypes = values;
                    break;
                case common.cacheValues.itemTrackingType:
                    values = await getValues(dateValues.value, 'itemTrackingTypes', 'values/' + common.valueTypes.itemTrackingType.id, tenant);
                    result.itemTrackingTypes = values;
                    break;
                case common.cacheValues.taxItem:
                    values = await getValues(dateValues.value, 'taxItems', 'values/' + common.valueTypes.taxItem.id, tenant);
                    result.taxItems = values;
                    break;
                case common.cacheValues.lotRating:
                    values = await getValues(dateValues.value, 'lotRatings', 'values/' + common.valueTypes.lotRating.id, tenant, tenant);
                    result.lotRatings = values;
                    break;
                case common.cacheValues.phoneType:
                    values = await getValues(dateValues.value, 'phoneTypes', 'values/' + common.valueTypes.phoneNumberType.id, tenant, tenant);
                    result.phoneTypes = values;
                    break;
                case common.cacheValues.vendorType:
                    values = await getValues(dateValues.value, 'vendorTypes', 'values/' + common.valueTypes.vendorType.id, tenant, tenant);
                    result.vendorTypes = values;
                    break;
                case common.cacheValues.licenseType:
                    values = await getValues(dateValues.value, 'licenseTypes', 'values/' + common.valueTypes.licenseType.id, tenant, tenant);
                    result.licenseTypes = values;
                    break;
                case common.cacheValues.qualificationStatus:
                    values = await getValues(dateValues.value, 'qualificationStatuses', 'values/' + common.valueTypes.qualificationStatus.id, tenant);
                    result.qualificationStatuses = values;
                    break;
                case common.cacheValues.certificationType:
                    values = await getValues(dateValues.value, 'certificationTypes', 'values/' + common.valueTypes.certificationType.id, tenant);
                    result.certificationTypes = values;
                    break;
                case common.cacheValues.purchaseOrderStatus:
                    values = await getValues(dateValues.value, 'purchaseOrderStatuses', 'values/' + common.valueTypes.purchaseOrderStatus.id, tenant);
                    result.purchaseOrderStatuses = values;
                    break;
                case common.cacheValues.destructionMethod:
                    values = await getValues(dateValues.value, 'destructionMethods', 'values/' + common.valueTypes.destructionMethod.id, tenant);
                    result.destructionMethods = values;
                    break;
                case common.cacheValues.roomCleaningType:
                    values = await getValues(dateValues.value, 'roomCleaningTypes', 'values/' + common.valueTypes.roomCleaningType.id, tenant);
                    result.roomCleaningTypes = values;
                    break;
                case common.cacheValues.equipmentType:
                    values = await getValues(dateValues.value, 'equipmentTypes', 'values/' + common.valueTypes.equipmentType.id, tenant);
                    result.equipmentTypes = values;
                    break;
                case common.cacheValues.maintenanceRequestCategory:
                    values = await getValues(dateValues.value, 'maintenanceRequestCategories', 'values/' + common.valueTypes.maintRequestCategory.id, tenant);
                    result.maintenanceRequestCategories = values;
                    break;
                case common.cacheValues.addressType:
                    values = await getValues(dateValues.value, 'addressTypes', 'values/' + common.valueTypes.addressType.id, tenant);
                    result.addressTypes = values;
                    break;
                case common.cacheValues.relation:
                    values = await getValues(dateValues.value, 'relations', 'values/' + common.valueTypes.relation.id, tenant);
                    result.relations = values;
                    break;
                case common.cacheValues.gender:
                    values = await getValues(dateValues.value, 'genders', 'values/' + common.valueTypes.gender.id, tenant);
                    result.genders = values;
                    break;
                case common.cacheValues.customerType:
                    values = await getValues(dateValues.value, 'customerTypes', 'values/' + common.valueTypes.customerType.id, tenant);
                    result.customerTypes = values;
                    break;
                case common.cacheValues.saleType:
                    values = await getValues(dateValues.value, 'saleTypes', 'values/' + common.valueTypes.saleType.id, tenant);
                    result.saleTypes = values;
                    break;
                case common.cacheValues.announcementStatus:
                    values = await getValues(dateValues.value, 'announcementStatuses', 'values/' + common.valueTypes.announcementStatus.id, tenant);
                    result.announcementStatuses = values;
                    break;
                case common.cacheValues.announcementType:
                    values = await getValues(dateValues.value, 'announcementTypes', 'values/' + common.valueTypes.announcementType.id, tenant);
                    result.announcementTypes = values;
                    break;
                case common.cacheValues.announcementPriority:
                    values = await getValues(dateValues.value, 'announcementPriorities', 'values/' + common.valueTypes.announcementPriority.id, tenant);
                    result.announcementPriorities = values;
                    break;
                case common.cacheValues.lengthUOM:
                    values = await getValues(dateValues.value, 'lengthUOMs', 'values/' + common.valueTypes.lengthUom.id, tenant);
                    result.lengthUOMs = values;
                    break;
                case common.cacheValues.productionPlanStatus:
                    values = await getValues(dateValues.value, 'productionPlanStatuses', 'values/' + common.valueTypes.productionPlanStatus.id, tenant);
                    result.productionPlanStatuses = values;
                    break;
                case common.cacheValues.treatmentType:
                    values = await getValues(dateValues.value, 'treatmentTypes', 'values/' + common.valueTypes.treatmentType.id, tenant);
                    result.treatmentTypes = values;
                    break;
                case common.cacheValues.externalTreatmentIssue:
                    values = await getValues(dateValues.value, 'externalTreatmentIssues', 'values/' + common.valueTypes.externalTreatmentIssue.id, tenant);
                    result.externalTreatmentIssues = values;
                    break;
                case common.cacheValues.sterilizationType:
                    values = await getValues(dateValues.value, 'sterilizationTypes', 'values/' + common.valueTypes.sterilizationType.id, tenant);
                    result.sterilizationTypes = values;
                    break;
                case common.cacheValues.customerComplaintType:
                    values = await getValues(dateValues.value, 'customerComplaintTypes', 'values/' + common.valueTypes.customerComplaintType.id, tenant);
                    result.customerComplaintTypes = values;
                    break;
                case common.cacheValues.seriousReaction:
                    values = await getValues(dateValues.value, 'seriousReactions', 'values/' + common.valueTypes.seriousReaction.id, tenant);
                    result.seriousReactions = values;
                    break;
                case common.cacheValues.deviationType:
                    values = await getValues(dateValues.value, 'deviationTypes', 'values/' + common.valueTypes.deviationType.id, tenant);
                    result.deviationTypes = values;
                    break;
                case common.cacheValues.deviationImpact:
                    values = await getValues(dateValues.value, 'deviationImpacts', 'values/' + common.valueTypes.deviationImpact.id, tenant);
                    result.deviationImpacts = values;
                    break;
                case common.cacheValues.deviationCategory:
                    values = await getValues(dateValues.value, 'deviationCategories', 'values/' + common.valueTypes.deviationCategory.id, tenant);
                    result.deviationCategories = values;
                    break;
                case common.cacheValues.deviationStatus:
                    values = await getValues(dateValues.value, 'deviationStatuses', 'values/' + common.valueTypes.deviationStatus.id, tenant);
                    result.deviationStatuses = values;
                    break;
                case common.cacheValues.deviationDisposition:
                    values = await getValues(dateValues.value, 'deviationDispositions', 'values/' + common.valueTypes.deviationDisposition.id, tenant);
                    result.deviationDispositions = values;
                    break;
                case common.cacheValues.action:
                    values = await getValues(dateValues.value, 'actions', 'values/' + common.valueTypes.action.id, tenant);
                    result.actions = values;
                    break;
                case common.cacheValues.productionScheduleCancelReason:
                    values = await getValues(dateValues.value, 'productionScheduleCancelReasons', 'values/' + common.valueTypes.productionScheduleCancelReason.id, tenant);
                    result.productionScheduleCancelReasons = values;
                    break;
                case common.cacheValues.productionScheduleStatus:
                    values = await getValues(dateValues.value, 'productionScheduleStatuses', 'values/' + common.valueTypes.productionScheduleStatus.id, tenant);
                    result.productionScheduleStatuses = values;
                    break;
                case common.cacheValues.observationType:
                    values = await getValues(dateValues.value, 'observationTypes', 'values/' + common.valueTypes.observationType.id, tenant);
                    result.observationTypes = values;
                    break;
                case common.cacheValues.documentCategory:
                    values = await getValues(dateValues.value, 'documentCategories', 'values/' + common.valueTypes.documentCategory.id, tenant);
                    result.documentCategories = values;
                    break;
                case common.cacheValues.documentFormat:
                    values = await getValues(dateValues.value, 'documentFormats', 'values/' + common.valueTypes.documentFormat.id, tenant);
                    result.documentFormats = values;
                    break;
                case common.cacheValues.documentStatus:
                    values = await getValues(dateValues.value, 'documentStatuses', 'values/' + common.valueTypes.documentStatus.id, tenant);
                    result.documentStatuses = values;
                    break;
                case common.cacheValues.documentType:
                    values = await getValues(dateValues.value, 'documentTypes', 'values/' + common.valueTypes.documentType.id, tenant);
                    result.documentTypes = values;
                    break;
                case common.cacheValues.bomLineType:
                    values = await getValues(dateValues.value, 'bomLineTypes', 'values/' + common.valueTypes.bomLineType.id, tenant);
                    result.bomLineTypes = values;
                    break;
                case common.cacheValues.ccxPostingStatus:
                    values = await getValues(dateValues.value, 'ccxPostingStatuses', 'values/' + common.valueTypes.ccxPostingStatus.id, tenant);
                    result.ccxPostingStatuses = values;
                    break;
                case common.cacheValues.growRoomType:
                    values = await getValues(dateValues.value, 'growRoomTypes', 'values/' + common.valueTypes.growRoomType.id, tenant);
                    result.growRoomTypes = values;
                    break;
                case common.cacheValues.exciseAdjustmentType:
                    values = await getValues(dateValues.value, 'exciseAdjustmentTypes', 'values/' + common.valueTypes.exciseAdjustmentType.id, tenant);
                    result.exciseAdjustmentTypes = values;
                    break;
                case common.cacheValues.maintenanceLogType:
                    values = await getValues(dateValues.value, 'maintenanceLogTypes', 'values/' + common.valueTypes.maintenanceLogType.id, tenant);
                    result.maintenanceLogTypes = values;
                    break;
                case common.cacheValues.equipmentCalibrationCheckType:
                    values = await getValues(dateValues.value, 'equipmentCalibrationCheckTypes', 'values/' + common.valueTypes.equipmentCalibrationCheckType.id, tenant);
                    result.equipmentCalibrationCheckTypes = values;
                    break;
                case common.cacheValues.machineType:
                    values = await getValues(dateValues.value, 'machineTypes', 'values/' + common.valueTypes.machineType.id, tenant);
                    result.machineTypes = values;
                    break;
		        case common.cacheValues.saleTerms:
                    values = await getValues(dateValues.value, 'saleTerms', 'values/' + common.valueTypes.saleTerms.id, tenant);
                    result.saleTerms = values;
                    break;
                case common.cacheValues.testResultValue:
                    values = await getValues(dateValues.value, 'testResultValues', 'values/' + common.valueTypes.testResultValue.id, tenant);
                    result.testResultValues = values;
                    break;
                case common.cacheValues.testTotalType:
                    values = await getValues(dateValues.value, 'testTypeTotals', 'values/' + common.valueTypes.testTotalType.id, tenant);
                    result.testTotalTypes = values;
                    break;
                case common.cacheValues.carSourceType:
                    values = await getValues(dateValues.value, 'carSourceTypes', 'values/' + common.valueTypes.carSourceType.id, tenant);
                    result.carSourceTypes = values;
                    break;
                case common.cacheValues.documentCertificationStatus:
                    values = await getValues(dateValues.value, 'documentCertificationStatuses', 'values/' + common.valueTypes.documentCertificationStatus.id, tenant);
                    result.documentCertificationStatuses = values;
                    break;
                case common.cacheValues.feedingFrequency:
                    values = await getValues(dateValues.value, 'feedingFrequencies', 'values/' + common.valueTypes.feedingFrequency.id, tenant);
                    result.feedingFrequencies = values;
                    break;
                case common.cacheValues.valueType: 
                    values = await getValues(dateValues.value, 'allValueTypes', 'valuetypeselect', tenant);
                    result.valueTypes = values;
                    break;
                case common.cacheValues.userDepartment:
                    values = await getValues(dateValues.department, 'userDepartments', 'userdepartments', tenant);
                    result.userDepartments = values;
                    break;
                case common.cacheValues.productionItem:
                    values = await getValues(dateValues.item, 'allProductionItems', 'productionitems', tenant);
                    result.productionItems = values;
                    break;
                case common.cacheValues.producedItem:
                    values = await getValues(dateValues.item, 'allProducedItems', 'produceditems', tenant);
                    result.producedItems = values;
                    break;
                case common.cacheValues.exciseItems:
                    values = await getValues(dateValues.item, 'allExciseItems', 'exciseitems', tenant);
                    result.exciseItems = values;
                    break;
                case common.cacheValues.ppeItem:
                    values = await getValues(dateValues.item, 'allPpeItems', 'ppeitems', tenant);
                    result.ppeItems = values;
                    break;
                case common.cacheValues.pestTrapItem:
                    values = await getValues(dateValues.item, 'allPestTrapItems', 'pestTrapitems', tenant);
                    result.pestTrapItems = values;
                    break;
                case common.cacheValues.feedingRecipeItem:
                    values = await getValues(dateValues.item, 'feedingRecipeItems', 'feedrecipeitems', tenant);
                    result.feedingRecipeItems = values;
                    break;
                case common.cacheValues.feedingLotItem:
                    values = await getValues(dateValues.item, 'allFeedLotItems', 'feedinglotitems', tenant);
                    result.feedingLotItems = values;
                    break;
                case common.cacheValues.feedingSolutionItem:
                    values = await getValues(dateValues.item, 'allFeedSolutionItems', 'feedingsolutionitems', tenant);
                    result.feedingSolutionItems = values;
                    break;
                case common.cacheValues.feedingLotIngredient:
                    values = await getValues(dateValues.item, 'allFeedIngredients', 'feedlotingredients', tenant);
                    result.feedingLotIngredients = values;
                    break;
                case common.cacheValues.roomFeedingItem:
                    values = await getValues(dateValues.item, 'allRoomFeedIngredients', 'feedingredients', tenant);
                    result.roomFeedingIngredients = values;
                    break;
                case common.cacheValues.pesticidePrepItems:
                    values = await getValues(dateValues.item, 'pesticidePrepItems', 'pesticidesolutions', tenant);
                    result.pesticideSolutions = values;
                    break;
                case common.cacheValues.pesticidePrepInputs:
                    values = await getValues(dateValues.item, 'pesticidePrepInputs', 'pesticideinputs', tenant);
                    result.pesticideInputs = values;
                    break;
                case common.cacheValues.trimmingStartItem:
                    values = await getValues(dateValues.item, 'trimmingStartItems', 'trimstartitems', tenant);
                    result.trimStartItems = values;
                    break;
                case common.cacheValues.cureStartItem:
                    values = await getValues(dateValues.item, 'cureStartItems', 'curestartitems', tenant);
                    result.cureStartItems = values;
                    break;
                case common.cacheValues.dryStartItem:
                    values = await getValues(dateValues.item, 'dryStartItems', 'drystartitems', tenant);
                    result.dryStartItems = values;
                    break;
                case common.cacheValues.wasteItems:
                    values = await getValues(dateValues.item, 'wasteItems', 'wasteitems', tenant);
                    result.wasteItems = values;
                    break;
                case common.cacheValues.cleaningSolutionItem:
                    values = await getValues(dateValues.item, 'cleaningSolutionItems', 'cleaningsolutionitems', tenant);
                    result.cleaningSolutionItems = values;
                    break;
                case common.cacheValues.cleanerItem:
                    values = await getValues(dateValues.item, 'cleanerItems', 'cleaneritems', tenant);
                    result.cleanerItems = values;
                    break;
                case common.cacheValues.batchActivityItems:
                    values = await getValues(dateValues.item, 'batchActivityItems', 'batchactitems', tenant);
                    result.batchActivityItems = values;
                    break;
                case common.cacheValues.ccxFlowerItem:
                    values = await getValues(dateValues.item, 'ccxFlowerItems', 'ccxfloweritems', tenant);
                    result.ccxFlowerItems = values;
                    break;
                case common.cacheValues.ccxTrimItem:
                    values = await getValues(dateValues.item, 'ccxTrimItems', 'ccxTrimitems', tenant);
                    result.ccxTrimItems = values;
                    break;
                case common.cacheValues.casedItem:
                    values = await getValues(dateValues.item, 'casedItems', 'caseditems', tenant);
                    result.casedItems = values;
                    break;
                case common.cacheValues.packagedItem:
                    values = await getValues(dateValues.item, 'packagedItems', 'packageditems', tenant);
                    result.packagedItems = values;
                    break;
                case common.cacheValues.caseLotItems:
                    values = await getValues(dateValues.item, 'caseLotItems', 'caselotitems', tenant);
                    result.caseLotItems = values;
                    break;
                case common.cacheValues.testableItem:
                    values = await getValues(dateValues.item, 'testableItems', 'testableitems', tenant);
                    result.testableItems = values;
                    break;
                case common.cacheValues.batchTemplate:
                    values = await getValues(dateValues.batchTemplate, 'allBatchTemplates', 'batchtemplateselect', tenant);
                    result.batchTemplates = values;
                    break;
                case common.cacheValues.batchTemplateActive:
                    values = await getValues(dateValues.batchTemplate, 'activeBatchTemplates', 'batchtemplateselect?active=true', tenant);
                    result.activeBatchTemplates = values;
                    break;
                case common.cacheValues.batchActivityDef:
                    values = await getValues(dateValues.batchActivityDef, 'allBatchAcitvityDefs', 'batchactivitydefinitionselect', tenant);
                    result.batchActivityDefs = values;
                    break;
                case common.cacheValues.batchActivityDefRegular:
                    values = await getValues(dateValues.batchActivityDef, 'batchAcitvityDefsReg', 'batchactivitydefinitionselectRegular', tenant);
                    result.batchActivityDefsReg = values;
                    break;
                case common.cacheValues.batchActivityDefTissue:
                    values = await getValues(dateValues.batchActivityDef, 'batchAcitvityDefsTissue', 'batchactivitydefinitionselectTissue', tenant);
                    result.batchActivityDefsTissue = values;
                    break;
                case common.cacheValues.managedDocument:
                    values = await getValues(dateValues.managedDocument, 'allManagedDocuments', 'managedocselect', tenant);
                    result.managedDocuments = values;
                    break;
                case common.cacheValues.documentAuthor:
                    values = await getValues(dateValues.user, 'allDocumentAuthors', 'documentauthors', tenant);
                    result.documentAuthors = values;
                    break;
                case common.cacheValues.documentReviewer:
                    values = await getValues(dateValues.user, 'documentReviewers', 'documentreviewers', tenant);
                    result.documentReviewers = values;
                    break;
                case common.cacheValues.feedingRecipe:
                    values = await getValues(dateValues.feedingRecipe, 'activeFeedingRecipes', 'feedingrecipeselect', tenant);
                    result.feedingRecipes = values;
                    break;
                case common.cacheValues.feedingRecipeAll:
                    values = await getValues(dateValues.feedingRecipe, 'allFeedingRecipes', 'feedingrecipeselect?hide=true', tenant);
                    result.feedingRecipesAll = values;
                    break;
                case common.cacheValues.workCenter:
                    values = await getValues(dateValues.workCenter, 'workCenters', 'workcenterselect', tenant);
                    result.workCenter = values;
                    break;
                case common.cacheValues.productionSchedule:
                    values = await getValues(dateValues.productionSchedules, 'prodScheds', 'productionscheduleselect', tenant);
                    result.productionSchedules = values;
                    break;
                case common.cacheValues.userSop:
                    values = await getValues(dateValues.sop, 'userSops', 'usersops', tenant);
                    result.userSops = values;
                    break;
                case common.cacheValues.managedDocumentTemplate:
                    values = await getValues(dateValues.managedDocumentTemplate, 'managedDocumentTemplateVals', 'documenttemplateselect', tenant);
                    result.managedDocumentTemplates = values;
                    break;
                case common.cacheValues.tenantReport:
                    values = await getValues(dateValues.tenantReport, 'tenantReportVals', 'tenantreportselect/' + objectId, tenant);
                    result.tenantReports = values;
                    break;
                case common.cacheValues.cleaningSolutionRecipe:
                    values = await getValues(dateValues.cleaningSolutionRecipe, 'cleaningSolutionRecipeVals', 'cleaningrecipeselect', tenant);
                    result.cleaningSolutionRecipes = values;
                    break;
                case common.cacheValues.location:
                case common.cacheValues.cultivationLocation:
                case common.cacheValues.processingLocation:
                case common.cacheValues.warehouseLocation:
                case common.cacheValues.workLocation:
                case common.cacheValues.activeLocation:
                case common.cacheValues.cultivationWarehouseLocation:
                case common.cacheValues.processingWarehouseLocation:
                    values = await getLocations(dateValues.location, row, tenant);
                    result.locations = values;
                    break;
                case common.cacheValues.room:
                case common.cacheValues.growingRoom:
                case common.cacheValues.processingRoom:
                case common.cacheValues.storageRoom:
                case common.cacheValues.defaultRoom:
                case common.cacheValues.defaultGrowingRoom:
                case common.cacheValues.defaultProcessingRoom:
                case common.cacheValues.defaultStorageRoom:
                    values = await getRooms(dateValues.room, row, tenant);
                    result.rooms = values;
                    break;
                case common.cacheValues.batchActivitySop:
                    values = await getValues(dateValues.sop, 'allBatchAcitvitySops', 'objectSops/' + constants.objectIds.batchActivity, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.cleaningSolutionSop:
                    values = await getValues(dateValues.sop, 'allCleaningSolutionSops', 'objectSops/' + constants.objectIds.cleaningSolution, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.cropTreatmentSop:
                    values = await getValues(dateValues.sop, 'allCropTreatmentSops', 'objectSops/' + constants.objectIds.cropTreatment, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.curingActivitySop:
                    values = await getValues(dateValues.sop, 'allCuringActivitySops', 'objectSops/' + constants.objectIds.curingActivity, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.feedingLotSop:
                    values = await getValues(dateValues.sop, 'allFeedingLotSops', 'objectSops/' + constants.objectIds.feedingLot, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.roomFeedingSop:
                    values = await getValues(dateValues.sop, 'roomFeedingSop', 'objectSops/' + constants.objectIds.roomFeeding, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.pesticidePrepSop:
                    values = await getValues(dateValues.sop, 'pesticidePrepSop', 'objectSops/' + constants.objectIds.pesticidePrep, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.trimmingActivitySop:
                    values = await getValues(dateValues.sop, 'trimmingActivitySops', 'objectSops/' + constants.objectIds.trimmingActivity, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.dryingActivitySop:
                    values = await getValues(dateValues.sop, 'dryingActivitySops', 'objectSops/' + constants.objectIds.dryingActivity, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.productionOrderSop:
                    values = await getValues(dateValues.sop, 'productionOrderSop', 'objectSops/' + constants.objectIds.productionOrder, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.productionOrderActivitySop:
                    values = await getValues(dateValues.sop, 'productionOrderActivitySop', 'objectSops/' + constants.objectIds.poActivity, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.shipmentSop:
                    values = await getValues(dateValues.sop, 'shipmentSop', 'objectSops/' + constants.objectIds.shipment, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.receiptSop:
                    values = await getValues(dateValues.sop, 'receiptSop', 'objectSops/' + constants.objectIds.receipt, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.destructionSop:
                    values = await getValues(dateValues.sop, 'destructionSop', 'objectSops/' + constants.objectIds.destruction, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.roomCleaningSop:
                    values = await getValues(dateValues.sop, 'roomCleaningSops', 'objectSops/' + constants.objectIds.roomCleaning, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.equipmentCalibrationSop:
                    values = await getValues(dateValues.sop, 'equipmentCalibrationSops', 'objectSops/' + constants.objectIds.equipmentCalibrationCheck, tenant);
                    result.sops = values;
                    break;
                case common.cacheValues.cropTreatmentForm:
                    values = await getValues(dateValues.object, 'cropTreatmentForm', 'objectform/' + constants.objectIds.cropTreatment, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.batchActivityForm:
                    values = await getValues(dateValues.object, 'batchActivityForm', 'objectform/' + constants.objectIds.batchActivity, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.feedingLotForm:
                    values = await getValues(dateValues.object, 'feedingLotForm', 'objectform/' + constants.objectIds.feedingLot, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.roomFeedingForm:
                    values = await getValues(dateValues.object, 'roomFeedingForm', 'objectform/' + constants.objectIds.roomFeeding, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.pesticidPrepForm:
                    values = await getValues(dateValues.object, 'pesticidePrepForm', 'objectform/' + constants.objectIds.pesticidePrep, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.trimmingOrderForm:
                    values = await getValues(dateValues.object, 'trimmingOrderForm', 'objectform/' + constants.objectIds.trimmingOrder, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.trimmingActivityForm:
                    values = await getValues(dateValues.object, 'trimmingActivityForm', 'objectform/' + constants.objectIds.trimmingActivity, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.dryingOrderForm:
                    values = await getValues(dateValues.object, 'dryingOrderForm', 'objectform/' + constants.objectIds.dryingOrder, tenant);
                    result.form = values;
                    break;
                 case common.cacheValues.dryingActivityForm:
                    values = await getValues(dateValues.object, 'dryingActivityForm', 'objectform/' + constants.objectIds.dryingActivity, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.curingForm:
                    values = await getValues(dateValues.object, 'curingForm', 'objectform/' + constants.objectIds.curing, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.curingActivityForm:
                    values = await getValues(dateValues.object, 'curingActivityForm', 'objectform/' + constants.objectIds.curingActivity, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.productionBatchForm:
                    values = await getValues(dateValues.object, 'productionBatchForm', 'objectform/' + constants.objectIds.productionBatch, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.productionOrderForm:
                    values = await getValues(dateValues.object, 'productionOrderForm', 'objectform/' + constants.objectIds.productionOrder, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.masterCaseForm:
                    values = await getValues(dateValues.object, 'masterCaseForm', 'objectform/' + constants.objectIds.masterCase, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.productionOrderActivityForm:
                    values = await getValues(dateValues.object, 'productionOrderActivityForm', 'objectform/' + constants.objectIds.poActivity, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.vendorForm:
                    values = await getValues(dateValues.object, 'vendorForm', 'objectform/' + constants.objectIds.vendor, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.shipmentForm:
                    values = await getValues(dateValues.object, 'shipmentForm', 'objectform/' + constants.objectIds.shipment, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.receiptForm:
                    values = await getValues(dateValues.object, 'receiptForm', 'objectform/' + constants.objectIds.receipt, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.purchaseOrderForm:
                    values = await getValues(dateValues.object, 'purchaseOrderForm', 'objectform/' + constants.objectIds.purchaseOrder, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.destructionForm:
                    values = await getValues(dateValues.object, 'destructionForm', 'objectform/' + constants.objectIds.destruction, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.roomCleaningForm:
                    values = await getValues(dateValues.object, 'roomCleaningForm', 'objectform/' + constants.objectIds.roomCleaning, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.equipmentLogForm:
                    values = await getValues(dateValues.object, 'equipmentLogForm', 'objectform/' + constants.objectIds.equipmentLog, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.maintenanceRequestForm:
                    values = await getValues(dateValues.object, 'maintenanceRequestForm', 'objectform/' + constants.objectIds.maintenanceRequest, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.cleaningSolutionForm:
                    values = await getValues(dateValues.object, 'cleaningSolutionForm', 'objectform/' + constants.objectIds.cleaningSolution, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.customerForm:
                    values = await getValues(dateValues.object, 'customerForm', 'objectform/' + constants.objectIds.customer, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.saleForm:
                    values = await getValues(dateValues.object, 'saleForm', 'objectform/' + constants.objectIds.sale, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.externalTreatmentForm:
                    values = await getValues(dateValues.object, 'externalTreatmentForm', 'objectform/' + constants.objectIds.externalTreatment, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.customerComplaintForm:
                    values = await getValues(dateValues.object, 'customerComplaintForm', 'objectform/' + constants.objectIds.customerComplaint, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.deviationForm:
                    values = await getValues(dateValues.object, 'deviationForm', 'objectform/' + constants.objectIds.deviation, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.batchObservationForm:
                    values = await getValues(dateValues.object, 'batchObservationForm', 'objectform/' + constants.objectIds.batchObservation, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.equipmentCalibrationForm:
                    values = await getValues(dateValues.object, 'equipmentCalibrationForm', 'objectform/' + constants.objectIds.equipmentCalibrationCheck, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.riskAssessmentForm:
                    values = await getValues(dateValues.object, 'riskAssessmentForm', 'objectform/' + constants.objectIds.riskAssessment, tenant);
                    result.form = values;
                    break;
                case common.cacheValues.testCategory:
                    values = await getValues(dateValues.object, 'testCategory', 'testcategories', tenant);
                    result.testCategories = values;
                    break;
                case common.cacheValues.label:
                    values = await getValues(dateValues.value, 'allLabels', 'values/' + common.valueTypes.labelList.id, tenant);
                    result.labels = values;
                    break;
                case common.cacheValues.labelPrintType:
                    values = await getValues(dateValues.value, 'allLabelTypes', 'values/' + common.valueTypes.labelPrintType.id, tenant);
                    result.labelPrintTypes = values;
                    break;
                case common.cacheValues.labelJustification:
                    values = await getValues(dateValues.value, 'allLabelJustifications', 'values/' + common.valueTypes.labelJustification.id, tenant);
                    result.labelJustifications = values;
                    break;
                case common.cacheValues.labelPrintQuality:
                    values = await getValues(dateValues.value, 'allLabelPrintQualities', 'values/' + common.valueTypes.labelPrintQuality.id, tenant);
                    result.labelPrintQualities = values;
                    break;
                case common.cacheValues.labelRotation:
                    values = await getValues(dateValues.value, 'allLabelRotations', 'values/' + common.valueTypes.labelRotation.id, tenant);
                    result.labelRotations = values;
                    break;
                case common.cacheValues.containerTypeAvailable:
                    values = await getValues(dateValues.value, 'containerTypeAvailables', 'containertypeavailable', tenant);
                    result.availableContainerTypes = values;
                    break;
                case common.cacheValues.containerTypeUnavailable:
                    values = await getValues(dateValues.value, 'containerTypeUnavailables', 'containertypeunavailable', tenant);
                    result.unavailableContainerTypes = values;
                    break;
                case common.cacheValues.containerTypeConsumption:
                    values = await getValues(dateValues.value, 'containerTypeConsumptions', 'containertypeconsumption', tenant);
                    result.consumptionContainerTypes = values;
                    break;
                case common.cacheValues.containerTypeLab:
                    values = await getValues(dateValues.value, 'containerTypeLabs', 'containertypelab', tenant);
                    result.labContainerTypes = values;
                    break;
                case common.cacheValues.containerTypeRetain:
                    values = await getValues(dateValues.value, 'containerTypeRetains', 'containertyperetain', tenant);
                    result.retainContainerTypes = values;
                    break;
                case common.cacheValues.containerTypeShipment:
                    values = await getValues(dateValues.value, 'containerTypeRetains', 'containertyperetain', tenant);
                    result.shipmentContainerTypes = values;
                    break;
                default:
                    break;
            }
        }
        return result;
    }, 

    async screenUsers(screen, tenant){
        let data = null;
        if(screen != null && screen !== ''){
            const key = 'screen-'  + tenant + screen;
            let cached = await getItem(key);
            if(cached != null){
                data = cached.data;
            } else {
                let result = await axios.get('/api/screenusers', {params: {screen: screen}});
                data = result.data;
                await setItem(key, data);
            }
        }
        return data;
    },

    authOptions(){
        let authTypes = [
            {value: common.auth.azure, label: 'Azure'}, 
            {value: common.auth.DB, label: 'Internal DB'},
            {value: common.auth.google, label: 'Google'}
        ];
        return authTypes;
    }
}

async function getRooms(serverDate, type, tenant){
    let obj = null;
    switch(type){
        case common.cacheValues.room:
            obj = await getItem('allRooms' + tenant);
            break;
        case common.cacheValues.growingRoom:
            obj = await getItem('growingRooms' + tenant);
            break;
        case common.cacheValues.processingRoom:
            obj = await getItem('processingRooms' + tenant);
            break;
        case common.cacheValues.storaggeRoom:
            obj = await getItem('storageRooms' + tenant);
            break;
        case common.cacheValues.defaultRoom:
            obj = await getItem('defaultLocationRooms' + tenant);
            break;
        case common.cacheValues.defaultGrowingRoom:
            obj = await getItem('defaultGrowingRooms' + tenant);
            break;
        case common.cacheValues.defaultProcessingRoom:
            obj = await getItem('defaultProcessingRooms' + tenant);
            break
        case common.cacheValues.defaultStorageRoom:
            obj = await getItem('defaultStorageRooms' + tenant);
            break;
        default:
            break;
    }
    let retrieve = false;
    let json = obj != null ? obj.data : null;
    if(json == null){
        retrieve = true;
    }
    if(!retrieve){
        retrieve = serverNewer(serverDate, obj.date);
    }
    if(retrieve){
        let result = await axios.get('/api/roomselectalllists');
        await setItem('allRooms' + tenant, result.data.all);
        await setItem('growingRooms' + tenant, result.data.growing);
        await setItem('processingRooms' + tenant, result.data.processing);
        await setItem('storageRooms' + tenant, result.data.storage);
        await setItem('defaultLocationRooms' + tenant, result.data.defaults);
        await setItem('defaultGrowingRooms' + tenant, result.data.defaultGrowing);
        await setItem('defaultProcessingRooms' + tenant, result.data.defaultProcessing);
        await setItem('defaultStorageRooms' + tenant, result.data.defaultStorage);
        switch(type){
            case common.cacheValues.room:
                json = result.data.all;
                break;
            case common.cacheValues.growingRoom:
                json = result.data.growing;
                break;
            case common.cacheValues.processingRoom:
                json = result.data.processing;
                break;
            case common.cacheValues.storaggeRoom:
                json = result.data.storage;
                break;
            case common.cacheValues.defaultRoom:
                json = result.data.defaults;
                break;
            case common.cacheValues.defaultGrowingRoom:
                json = result.data.defaultGrowing;
                break;
            case common.cacheValues.defaultProcessingRoom:
                json = result.data.defaultProcessing;
                break
            case common.cacheValues.defaultStorageRoom:
                json = result.data.defaultStorage;
                break;
            default:
                break;
        }
    }
    return json;
}

async function getLocations(serverDate, locType, tenant){
    let dataObj = null;
    switch (locType){
        case common.cacheValues.location:
            dataObj = await getItem('allLocations' + tenant);
            break;
        case common.cacheValues.cultivationLocation:
            dataObj = await getItem('cultivationLocations' + tenant);
            break;
        case common.cacheValues.processingLocation:
            dataObj = await getItem('processingLocations' + tenant);
            break;
        case common.cacheValues.warehouseLocation:
            dataObj = await getItem('warehouseLocations' + tenant);
            break;
        case common.cacheValues.workLocation:
            dataObj = await getItem('workLocations' + tenant);
            break;
        case common.cacheValues.activeLocation:
            dataObj = await getItem('activeLocations' + tenant);
            break;
        case common.cacheValues.cultivationWarehouseLocation:
            dataObj = await getItem('cultivatioWarehouseLocations' + tenant);
            break;
        case common.cacheValues.processingWarehouseLocation:
            dataObj = await getItem('processingWarehouseLocations' + tenant);
            break;
        default:
           return;
    }
    let retrieve = false
    let json = dataObj != null ? dataObj.data : null;
    if(json == null){
        retrieve = true;
    }
    if(!retrieve){
        retrieve = serverNewer(serverDate, dataObj.date);
    }
    if(retrieve){
        let result = await axios.get('/api/locationselectall');
        await setItem('allLocations' + tenant, result.data.all);
        await setItem('cultivationLocations' + tenant, result.data.cultivatio);
        await setItem('processingLocations' + tenant, result.data.processing);
        await setItem('warehouseLocations' + tenant, result.data.warehouse);
        await setItem('workLocations' + tenant, result.data.working);
        await setItem('activeLocations' + tenant, result.data.active);
        await setItem('cultivatioWarehouseLocations' + tenant, result.data.cultWarehouse);
        await setItem('processingWarehouseLocations' + tenant, result.data.procWarehouse);
        switch (locType){
            case common.cacheValues.location:
                json = result.data.all;
                break;
            case common.cacheValues.cultivationLocation:
                json = result.data.cultivation;
                break;
            case common.cacheValues.processingLocation:
                json = result.data.processing;
                break;
            case common.cacheValues.warehouseLocation:
                json = result.data.warehouse;
                break;
            case common.cacheValues.workLocation:
                json = result.data.working;
                break;
            case common.cacheValues.activeLocation:
                json = result.data.active;
                break;
            case common.cacheValues.cultivationWarehouseLocation:
                json = result.data.cultWarehouse;
                break;
            case common.cacheValues.processingWarehouseLocation:
                json = result.data.procWarehouse;
                break;
            default:
                return;
        }
    }
    return json;
}

async function getValues(serverDate, objName, endpoint, tenant){
    const key = objName + tenant;
    let dataObj = await getItem(key);
    let json = dataObj != null ? dataObj.data : null;

    let cacheDateRaw = dataObj != null ? dataObj.date : null;
    let retrieve = false
    if(json == null){
        retrieve = true;
    }
    if(!retrieve && cacheDateRaw != null && serverDate != null){
        retrieve = serverNewer(serverDate, cacheDateRaw);
    }
    if(retrieve){
        let data = await axios.get('/api/' + endpoint);
        json = data.data;
        await setItem(key, json);
    } 
    return json;
}

function serverNewer(serverDate, cacheDate){
    let serverRealDate = serverDate != null && serverDate !== '' ? Date.parse(serverDate) : null;
    let cacheRealDate = cacheDate != null && cacheDate !== '' ? Date.parse(cacheDate) : null;
    let result = cacheRealDate == null || serverRealDate == null ? true : serverRealDate > cacheRealDate ? true : false;
    return result;
}

async function setItem(name, value){
    let record = await db.cache.where({id: name}).first();
    try {
        if(record != null){
            await db.cache.delete(name);
        }
        await db.cache.add({id: name, date: new Date().toISOString(), data: value});
    } catch(err){
        console.error('add ' + name);
        console.error(err);
    }
    
}

async function getItem(name){
    let value = await db.cache.where({id:name}).first();
    return value;
}

export default valueHelper;
