import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Chip from '@mui/material/Chip';
import CancelIcon from "@mui/icons-material/Cancel";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250
        }
    },
    // getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

export default function SelectField(props) {
    const { defaultValue, disabled, error, id, label, onChange, onDelete,
        linkTo, value, options, required, selectAll, selectAllLabel } = props;
    const [open, setOpen] = useState(false);

    let isAllSelected = false;
    if (selectAll) {
        isAllSelected = options != null && options.length > 0 && value != null && value.length === options.length;
    }

    const intChange = (event) => {
        const values = event.target.value;
        if(!Array.isArray(values)) {
            return;
        }
        let value = values[values.length - 1];
        if (value === 'all') {
            setOpen(!open);
        }
        onChange(event);
    }

    const handleChange = (event) => {
        onChange(event);
    }

    return !selectAll ?
        <FormControl sx={{ minWidth: '100%' }} disabled={disabled}>
            <InputLabel id={`${id}-label`} sx={{ ml: -1.6, mt: 0.5 }}>{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                id={`select-${id}`}
                value={value}
                onChange={handleChange}
                defaultValue={defaultValue ?? ''}
                input={<Input />}
                required={required}
                variant={props.variant != null ? props.variant : 'standard'}
            >
                {options != null && options !== '' &&
                    options.map((option, indx) => <MenuItem value={option.value} key={indx}>{option.label}</MenuItem>)}
            </Select>
            <FormHelperText sx={{ color: 'error.main' }}>{error}</FormHelperText>
        </FormControl>
        :
        <FormControl sx={{ minWidth: '100%' }} disabled={disabled}>
            <InputLabel id={`${id}-label`} sx={{ ml: -1.6, mt: 0.5 }}>{label}</InputLabel>
            <Select
                labelId={`${id}-multi-select-label`}
                id={`multi-select-${id}`}
                multiple
                value={value || []}
                onChange={intChange}
                defaultValue={defaultValue || ''}
                input={<Input />}
                required={required}
                renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {selected.map((value) => {
                            if(value != null){
                                return (
                                    <Chip
                                        size='small'
                                        key={value.value}
                                        label={
                                            value?.label != null && value?.label !== '' &&
                                            value?.quantity != null && value?.quantity !== '' ?
                                                value.label + '(' + value.quantity + ')' :
                                            value?.label != null && value?.label !== '' ? value.label : 'None'
                                        }
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                            />
                                        }
                                        sx={{ m: 0.5 }}
                                        onDelete={!disabled ? (e) => onDelete(e, value) : null}
                                        onClick={linkTo != null && value?.value != null && value?.value !== '' ? () => window.location.href = linkTo + value?.value : null}
                                    />
                                );    
                            }else {
                                return null;
                            }                                                    
                        })}
                    </div>
                )}
                MenuProps={MenuProps}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                open={open}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? {
                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.08)"
                            }
                        } : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            // sx={{ '.MuiCheckbox-indeterminate': {color: 'primary.main'} }}
                            checked={isAllSelected}
                            indeterminate={options != null && value != null &&
                                value.length > 0 && value.length < options.length
                            }
                            indeterminateIcon={<IndeterminateCheckBoxIcon sx={{ color: 'secondary.main' }} />}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ fontWeight: 600 }}
                        primary={selectAllLabel}
                    />
                </MenuItem>
                {options.map((option) => {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            <ListItemIcon>
                                <Checkbox checked={value?.length > 0 && value[0] != null ? value.some(x => x?.value === option?.value) : false} color='secondary' />
                            </ListItemIcon>
                            {option.quantity != null && option.quantity !== '' ?
                                <ListItemText primary={option.label + '(' + option.quantity + ')'} /> :
                                <ListItemText primary={option.label} />
                            }
                        </MenuItem>
                    )
                })}
            </Select>
            <FormHelperText sx={{ color: 'error.main' }}>{error}</FormHelperText>
        </FormControl>;
}