import { CREATE_ERROR, CLEAR_ERROR }  from '../actions/types';

const initialState = {
    list: {}
};

export default function errorReducer(state = initialState, action){
    switch(action.type){
        case CREATE_ERROR: {
            return {
                ...state,
                list: action.payload
            }
        }
        case CLEAR_ERROR:
            return initialState;
        default:
            return state;
    }
}