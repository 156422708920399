export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_SOP_ID = 'SET_SOP_ID';
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const SET_MESSAGE_COUNT = "SET_MESSAGE_COUNT";
export const SET_PROFILE = "SET_PROFILE";
export const SET_ACTIVITY_COUNT = "SET_ACTIVITY_COUNT";
export const SET_NAVMENU_LOCATION = "SET_NAVMENU_LOCATION";
export const SET_NAV_CRUMBS = "SET_NAV_CRUMBS";
export const OPEN_NEW_NAVMENU = "OPEN_NEW_NAVMENU";
export const TOGGLE_NAVMENU = "TOGGLE_NAVMENU";
export const TOGGLE_NAV_DRAWER = "TOGGLE_NAV_DRAWER";
export const CREATE_ALERT = "CREATE_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const SET_MENU_OPTIONS = "SET_MENU_OPTIONS";
export const SAVE_SECURITY = "SAVE_SECURITY";
export const SET_SECURITY = "SET_SECURITY";
export const SAVE_CACHE = "SAVE_CACHE";
export const RESET_SECURITY = "RESET_SECURITY";
export const RESET_AUTH = "RESET_AUTH";
export const RESET_NAVMENU = "RESET_NAVMENU";
export const RESET_PROFILE = "RESET_PROFILE";
export const TOGGLE_NAVSUBMENU = "TOGGLE_NAVSUBMENU";
export const OPEN_NEW_NAVSUBMENU = "OPEN_NEW_NAVSUBMENU";
export const SET_CONSTANTS = "SET_CONSTANTS";
export const TOGGLE_OPTIONMENU = "TOGGLE_OPTIONMENU";
export const SET_OPTIONMENU = "SET_OPTIONMENU";
export const RESET_OPTIONMENU = "RESET_OPTIONMENU";
export const SET_ZEBRA_PRINTERS = "SET_ZEBRA_PRINTERS";
export const SET_DEVICE_STATUS = "SET_DEVICE_STATUS";
export const RESET_DEVICES = "RESET_DEVICES";
export const SET_ZEBRA_DEFAULT = "SET_ZEBRA_DEFAULT";
export const HAS_DEVICES = "HAS_DEVICES";
export const TOGGLE_DEVICEDIALOG = "TOGGLE_DEVICEDIALOG";
export const TOGGLE_PRINTDIALOG = "TOGGLE_PRINTDIALOG";
export const TOGGLE_REGISTRATIONDIALOG = "TOGGLE_REGISTRATIONDIALOG";
export const SCREEN_LOCK = "SCREEN_LOCK";
export const BLUR_SCREEN = "BLUR_SCREEN";
export const SET_HELP_CURRENTPAGE = "SET_HELP_CURRENTPAGE";
export const SAVE_HELP_CACHE = "SET_HELP_CURRENTPAGE";
export const RESET_HELP = "RESET_HELP";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const TOGGLE_LOGS = "TOGGLE_LOGS";
export const TOGGLE_COMMENTS = "TOGGLE_COMMENTS";
export const CREATE_ERROR = "CREATE_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";