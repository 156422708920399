import React from 'react';
import Tooltip from '@mui/material/Tooltip';
//Clinics = LocalHospitalRounded @ material-ui
//import DataIcon from '@mui/icons-material/DataUsageTwoTone';
//import RoomDataIcon from '@mui/icons-material/SelectAllTwoTone';
//import FormIcon from '@mui/icons-material/PostAddTwoTone';
//import WorkRecordsIcon from '@mui/icons-material/AssignmentTurnedInTwoTone'
//import PestDestruction from '@mui/icons-material/BugReportTwoTone';
//import ApprovalIcon from 'mdi-material-ui/Draw';
//import ActivityDefinitionIcon from 'mdi-material-ui/ClipboardEditOutline';
import OpacityTwoTone from '@mui/icons-material/OpacityTwoTone';
import WarningTwoTone from '@mui/icons-material/WarningTwoTone';
import AccountNetworkOutline from 'mdi-material-ui/AccountNetworkOutline';
import Api from 'mdi-material-ui/Api';
import BagPersonalOutline from 'mdi-material-ui/BagPersonalOutline';
import Ballot from 'mdi-material-ui/Ballot';
import BucketOutline from 'mdi-material-ui/BucketOutline';
import Cash from 'mdi-material-ui/Cash';
import ClockTimeTwoOutline from 'mdi-material-ui/ClockTimeTwoOutline';
import ContentCut from 'mdi-material-ui/ContentCut';
import CubeScan from 'mdi-material-ui/CubeScan';
import Doctor from 'mdi-material-ui/Doctor';
import EmoticonPoop from 'mdi-material-ui/EmoticonPoop';
import FileChart from 'mdi-material-ui/FileChart';
import FileCog from 'mdi-material-ui/FileCog';
import FileUpload from 'mdi-material-ui/FileUpload';
import FolderMultiple from 'mdi-material-ui/FolderMultiple';
import FolderText from 'mdi-material-ui/FolderText';
import GaugeEmpty from 'mdi-material-ui/GaugeEmpty';
import GaugeFull from 'mdi-material-ui/GaugeFull';
import GaugeLow from 'mdi-material-ui/GaugeLow';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import HomeAnalytics from 'mdi-material-ui/HomeAnalytics';
import RocketLaunchOutline from 'mdi-material-ui/RocketLaunchOutline';
import ToolboxOutline from 'mdi-material-ui/ToolboxOutline';
import FormatListBulletedType from 'mdi-material-ui/FormatListBulletedType';
import FileTableBoxMultiple from 'mdi-material-ui/FileTableBoxMultiple';
import PageLayoutHeaderFooter from 'mdi-material-ui/PageLayoutHeaderFooter';
import TextBoxPlus from 'mdi-material-ui/TextBoxPlus';
import Ticket from 'mdi-material-ui/Ticket';
import Timetable from 'mdi-material-ui/Timetable';
import TruckOutline from 'mdi-material-ui/TruckOutline';
import TextBoxMultipleOutline from 'mdi-material-ui/TextBoxMultipleOutline';
import SprinklerVariant from 'mdi-material-ui/SprinklerVariant';
import WateringCanOutline from 'mdi-material-ui/WateringCanOutline';
import ShieldAccount from 'mdi-material-ui/ShieldAccount';
import Spray from 'mdi-material-ui/Spray';
import TumbleDryer from 'mdi-material-ui/TumbleDryer';
import WindowShutterOpen from 'mdi-material-ui/WindowShutterOpen';
import WrenchClock from 'mdi-material-ui/WrenchClock';
// import AccountCircleTwoTone from '@mui/icons-material/AccountCircleTwoTone';
import AccountTreeTwoTone from '@mui/icons-material/AccountTreeTwoTone';
import AddAlarmTwoTone from '@mui/icons-material/AddAlarmTwoTone';
import AllInboxTwoTone from '@mui/icons-material/AllInboxTwoTone';
import AssignmentTurnedInTwoTone from '@mui/icons-material/AssignmentTurnedInTwoTone';
import AssignmentTwoTone from '@mui/icons-material/AssignmentTwoTone';
import AutorenewTwoTone from '@mui/icons-material/AutorenewTwoTone';
import BallotTwoTone from '@mui/icons-material/BallotTwoTone';
import Brightness5TwoTone from '@mui/icons-material/Brightness5TwoTone';
import BuildTwoTone from '@mui/icons-material/BuildTwoTone';
import BugReportTwoTone from '@mui/icons-material/BugReportTwoTone';
import CallSplitTwoTone from '@mui/icons-material/CallSplitTwoTone';
import CardMembershipTwoTone from '@mui/icons-material/CardMembershipTwoTone';
import ContactsTwoTone from '@mui/icons-material/ContactsTwoTone';
import DateRangeTwoTone from '@mui/icons-material/DateRangeTwoTone';
import DeleteSweepTwoTone from '@mui/icons-material/DeleteSweepTwoTone';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import DescriptionTwoTone from '@mui/icons-material/DescriptionTwoTone';
import DeviceHubTwoTone from '@mui/icons-material/DeviceHubTwoTone';
import DraftsTwoTone from '@mui/icons-material/DraftsTwoTone';
import DvrTwoTone from '@mui/icons-material/DvrTwoTone';
import EmailTwoTone from '@mui/icons-material/EmailTwoTone';
import ErrorTwoTone from '@mui/icons-material/ErrorTwoTone';
import EmojiPeopleTwoTone from '@mui/icons-material/EmojiPeopleTwoTone';
import EventTwoTone from '@mui/icons-material/EventTwoTone';
import HealingTwoTone from '@mui/icons-material/HealingTwoTone';
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone';
import HowToRegTwoTone from '@mui/icons-material/HowToRegTwoTone';
import HowToVoteTwoTone from '@mui/icons-material/HowToVoteTwoTone';
import ImportantDevicesTwoTone from '@mui/icons-material/ImportantDevicesTwoTone';
import InfoTwoTone from '@mui/icons-material/InfoTwoTone';
import InsertDriveFileTwoTone from '@mui/icons-material/InsertDriveFileTwoTone';
import InvertColorsTwoTone from '@mui/icons-material/InvertColorsTwoTone';
import LayersTwoTone from '@mui/icons-material/LayersTwoTone';
import ListAltTwoTone from '@mui/icons-material/ListAltTwoTone';
import LocalFloristTwoTone from '@mui/icons-material/LocalFloristTwoTone';
import LocationOnTwoTone from '@mui/icons-material/LocationOnTwoTone';
import MeetingRoomTwoTone from '@mui/icons-material/MeetingRoomTwoTone';
import MenuOpenOutlined from '@mui/icons-material/MenuOpenOutlined';
import MmsTwoTone from '@mui/icons-material/MmsTwoTone';
import OutlinedFlagTwoTone from '@mui/icons-material/OutlinedFlagTwoTone';
import PeopleOutlineTwoTone from '@mui/icons-material/PeopleOutlineTwoTone';
import PolicyTwoTone from '@mui/icons-material/PolicyTwoTone';
import PublicTwoTone from '@mui/icons-material/PublicTwoTone';
import RecentActorsTwoTone from '@mui/icons-material/RecentActorsTwoTone';
import ReceiptTwoTone from '@mui/icons-material/ReceiptTwoTone';
import RestorePageTwoTone from '@mui/icons-material/RestorePageTwoTone';
import RotateLeftRounded from '@mui/icons-material/RotateLeftRounded';
import ShoppingCartTwoTone from '@mui/icons-material/ShoppingCartTwoTone';
import SortRounded from '@mui/icons-material/SortRounded';
import SubjectTwoTone from '@mui/icons-material/SubjectTwoTone';
import SupervisorAccountTwoTone from '@mui/icons-material/SupervisorAccountTwoTone';
import SwapCallsTwoTone from '@mui/icons-material/SwapCallsTwoTone';
import TuneRounded from '@mui/icons-material/TuneRounded';
import VerifiedUserTwoTone from '@mui/icons-material/VerifiedUserTwoTone';
import ViewColumnTwoTone from '@mui/icons-material/ViewColumnTwoTone';
import VisibilityTwoTone from '@mui/icons-material/VisibilityTwoTone';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CalculatorVariant from 'mdi-material-ui/CalculatorVariant';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import AnnouncementIcon from 'mdi-material-ui/BullhornVariant';
import RoomFeedingIcon from 'mdi-material-ui/Waves';
import FeedingRecipeIcon from 'mdi-material-ui/FormatListNumbered';
import PesticideRecipeIcon from '@mui/icons-material/GasMeter';
import BugCheckIcon from 'mdi-material-ui/BugCheck';
import CcxPostingIcon from '@mui/icons-material/StorefrontTwoTone';
import ExAdjIcon from '@mui/icons-material/TrendingDownTwoTone';
import EquipmentCalibrationCheckIcon from '@mui/icons-material/Check';
import EquipmentCalibrationTypeIcon from '@mui/icons-material/ChecklistRtl';
import InvoiceIcon from '@mui/icons-material/RequestPageTwoTone';
import AvailableInventoryIcon from '@mui/icons-material/Inventory2';
import ExciseStampIcon from '@mui/icons-material/VerifiedUser';
import MasterCaseIcon from '@mui/icons-material/CasesTwoTone';
import CapaIcon from '@mui/icons-material/PendingActionsTwoTone';
import BatchActivityIcon from '@mui/icons-material/LocalActivityTwoTone';
import CleaningSolutionRecipeIcon from '@mui/icons-material/SanitizerTwoTone';
import RiskAssessmentIcon from '@mui/icons-material/WarningTwoTone';
import PastInventoryIcon from '@mui/icons-material/InventoryTwoTone';

export default function Icons(props) {
    const { sx, screenDefs, screenDef, placement, title } = props;
    let icon = null;
    switch(screenDef) {
        case screenDefs.LaunchPad:
            icon = <RocketLaunchOutline sx={sx} />;
            break;
        case screenDefs.Home:
            icon = <HomeAnalytics sx={sx} />;
            break;
        case screenDefs.QADashboard:
            icon = <GaugeEmpty sx={sx} />;
            break;
        case screenDefs.InventoryDashboard:
            icon = <AssessmentTwoToneIcon sx={sx} />;
            break;
        case screenDefs.CultivationDashboard:
            icon = <GaugeLow sx={sx} />;
            break;
        case screenDefs.ProcessingDashboard:
            icon = <GaugeFull sx={sx} />;
            break;
        case screenDefs.DestructionList:
        case screenDefs.Destruction:
            icon = <DeleteTwoTone sx={sx} />;
            break;
        case screenDefs.DestructionReport:
            icon = <DeleteSweepTwoTone sx={sx} />;
            break;
        case screenDefs.NonConformance:
        case screenDefs.NcrList:
            icon = <SwapCallsTwoTone sx={sx} />;
            break;
        case screenDefs.IncidentList:
        case screenDefs.Incident:
            icon = <ErrorTwoTone sx={sx} />;
            break;
        case screenDefs.Deviation:
        case screenDefs.DeviationList:
            icon = <CallSplitTwoTone sx={sx} />;
            break;
        case screenDefs.LossCannabisList:
        case screenDefs.LossCannabis:
            icon = <PolicyTwoTone sx={sx} />;
            break;
        case screenDefs.InjuryHazardous:
        case screenDefs.InjuryHazardousList:
            icon = <WarningTwoTone sx={sx} />;
            break;
        case screenDefs.EmployeeIllnessList:
        case screenDefs.EmployeeIllness:
            icon = <HealingTwoTone sx={sx} />;
            break;
        case screenDefs.Recall:
        case screenDefs.RecallList:
            icon = <AutorenewTwoTone sx={sx} />;
            break;
        case screenDefs.VendorAuditList:
        case screenDefs.VendorAudit:
            icon = <VisibilityTwoTone sx={sx} />;
            break;
        case screenDefs.SigninList:
        case screenDefs.Signin:
            icon = <HowToRegTwoTone sx={sx} />;
            break;
        case screenDefs.VisitorList:
        case screenDefs.Visitor:
            icon = <EmojiPeopleTwoTone sx={sx} />;
            break;
        case screenDefs.IPMSolutionList:
        case screenDefs.IPMSolution:
            icon = <Spray sx={sx} />;
            break;
        case screenDefs.EquipmentLogs:
        case screenDefs.EquipmentLog:
            icon = <WrenchClock sx={sx} />;
            break;
        case screenDefs.ExternalTreatmentList:
        case screenDefs.ExternalTreatment:
            icon = <Brightness5TwoTone sx={sx} />;
            break;
        case screenDefs.RoomSettings:
        case screenDefs.Rooms:
        case screenDefs.Room:
            icon = <MeetingRoomTwoTone sx={sx} />;
            break;
        case screenDefs.RoomSummary:
            icon = <Timetable sx={sx} />;
            break;
        case screenDefs.LocationList:
        case screenDefs.LocationDetail:
        case screenDefs.LocationInspection:
            icon = <LocationOnTwoTone sx={sx} />;
            break;
        case screenDefs.CleaningSolutionList:
        case screenDefs.CleaningSolution:
            icon = <InvertColorsTwoTone sx={sx} />;
            break;
        case screenDefs.FeedingLotList:
        case screenDefs.FeedingLot:
            icon = <WateringCanOutline sx={sx} />;
            break;
        case screenDefs.SaturationTestList:
        case screenDefs.SaturationTest:
            icon = <OpacityTwoTone sx={sx} />;
            break;
        case screenDefs.PractitionerList:
        case screenDefs.Practitioner:
            icon = <Doctor sx={sx} />;
            break;
        case screenDefs.CustomerList:
        case screenDefs.Customer:
            icon = <RecentActorsTwoTone sx={sx} />;
            break;
        // case screenDefs.Prescriptions:
        // case screenDefs.Prescription:
        //     icon = < sx={sx} />;
        //     break;
        case screenDefs.EquipmentList:
        case screenDefs.Equipment:
            icon = <BuildTwoTone sx={sx} />;
            break;
        case screenDefs.SopList: //Work Record
        case screenDefs.SopInstance:
        case screenDefs.SopInstanceList:
        case screenDefs.WorkRecords:
            icon = <FileCog sx={sx} />;
            break;
        case screenDefs.ManageUsers:
        case screenDefs.ManageUser:
            icon = <SupervisorAccountTwoTone sx={sx} />;
            break;
        case screenDefs.TenantUsers:
        case screenDefs.TenantUser:
            icon = <PeopleOutlineTwoTone sx={sx} />;
            break;
        case screenDefs.UserList:
        case screenDefs.User:
            icon = <ShieldAccount sx={sx} />;
            break;
        case screenDefs.VendorList:
        case screenDefs.Vendor:
            icon = <ContactsTwoTone sx={sx} />;
            break;
        // case screenDefs.schedule:
        //     icon = < sx={sx} />;
        //     break;
        case screenDefs.RoomCheckList:
        case screenDefs.RoomCheck:
            icon = <LayersTwoTone sx={sx} />;
            break;
        case screenDefs.IPMTreatmentList:
        case screenDefs.IPMTreatment:
            icon = <SprinklerVariant sx={sx} />;
            break;
        case screenDefs.TestDefinitionList:
        case screenDefs.TestDefinition:
            icon = <DescriptionTwoTone sx={sx} />;
            break;
        case screenDefs.TestResultList:
        case screenDefs.TestResults:
            icon = <HowToVoteTwoTone sx={sx} />;
            break;
        case screenDefs.TestItemList:
            icon = <OutlinedFlagTwoTone sx={sx} />;
            break;
        case screenDefs.TestItemCategories:
        case screenDefs.TestItemCategoriesList:
            icon = <SortRounded sx={sx} />;
            break;
        case screenDefs.LotList:
        case screenDefs.Lot:
            icon = <MenuOpenOutlined sx={sx} />;
            break;
        case screenDefs.TaskRiskAssessmentList:
        case screenDefs.TaskRiskAssessment:
            icon = <ListAltTwoTone sx={sx} />;
            break;
        case screenDefs.MaintenanceRequestList:
        case screenDefs.MaintenanceRequest:
            icon = <ToolboxOutline sx={sx} />;
            break;
        case screenDefs.TenantList:
        case screenDefs.Tenant:
            icon = <AccountNetworkOutline sx={sx} />;
            break;
        case screenDefs.HCReportList:
        case screenDefs.HCReport:
            icon = <TextBoxPlus sx={sx} />;
            break;
        case screenDefs.ProductionOrderActivity:
        case screenDefs.ProductionOrderList:
        case screenDefs.ProductionOrder:
            icon = <AccountTreeTwoTone sx={sx} />;
            break;
        case screenDefs.ManagedDocumentList:
        case screenDefs.ManagedDocument:
            icon = <InsertDriveFileTwoTone sx={sx} />;
            break;
        case screenDefs.DocumentReviewList:
        case screenDefs.DocumentReview:
            icon = <AssignmentTurnedInTwoTone sx={sx} />;
            break;
        case screenDefs.Routings:
        case screenDefs.Routing:
            icon = <DeviceHubTwoTone sx={sx} />;
            break;
        case screenDefs.ProductionBoms:
        case screenDefs.ProductionBom:
            icon = <BallotTwoTone sx={sx} />;
            break;
        case screenDefs.ProductionPlanList:
        case screenDefs.ProductionPlan:
            icon = <AddAlarmTwoTone sx={sx} />;
            break;
        case screenDefs.ProductionScheduleList:
        case screenDefs.ProductionSchedule:
            icon = <DateRangeTwoTone sx={sx} />;
            break;
        case screenDefs.ScheduledActivities:
        case screenDefs.ScheduledActivity:
            icon = <EventTwoTone sx={sx} />;
            break;
        case screenDefs.ChangeRequestList:
        case screenDefs.ChangeRequest:
            icon = <AssignmentTwoTone sx={sx} />;
            break;
        case screenDefs.BatchActivityDefinitions:
            icon = <FolderText sx={sx} />;
            break;
        case screenDefs.BatchTemplates:
        case screenDefs.BatchTemplate:
            icon = <FolderMultiple sx={sx} />;
            break;
        case screenDefs.BatchObservation:
        case screenDefs.BatchActivity:
        case screenDefs.CultivationBatchList:
        case screenDefs.CultivationBatch:
            icon = <TextBoxMultipleOutline sx={sx} />;
            break;
        case screenDefs.ToteList:
            icon = <BucketOutline sx={sx} />;
            break;
        case screenDefs.ContainerList:
        case screenDefs.Container:
            icon = <BagPersonalOutline sx={sx} />;
            break;
        // case screenDefs.external:
        //     icon = < sx={sx} />;
        //     break;
        case screenDefs.ItemLedgers:
        case screenDefs.ItemLedger:
            icon = <ListAltTwoTone sx={sx} />;
            break;
        case screenDefs.BinList:
        case screenDefs.Bin:
            icon = <AllInboxTwoTone sx={sx} />;
            break;
        case screenDefs.BinSummary:
            icon = <FileTableBoxMultiple sx={sx} />;
            break;
        case screenDefs.SalesList:
        case screenDefs.Sale:
            icon = <Cash sx={sx} />;
            break;
        case screenDefs.Strains:
        case screenDefs.Strain:
            icon = <LocalFloristTwoTone sx={sx} />;
            break;
        case screenDefs.WorkCenterList:
        case screenDefs.WorkCenter:
            icon = <WindowShutterOpen sx={sx} />;
            break;
        case screenDefs.ShipmentList:
        case screenDefs.Shipment:
            icon = <TruckOutline sx={sx} />;
            break;
        case screenDefs.DocumentCertificationList:
        case screenDefs.DocumentCertification:
            icon = <CardMembershipTwoTone sx={sx} />;
            break;
        case screenDefs.DryingActivity:
        case screenDefs.DryingOrder:
        case screenDefs.DryingOrderList:
            icon = <TumbleDryer sx={sx} />;
            break;
        case screenDefs.TrimmingActivity:
        case screenDefs.TrimmingOrder:
        case screenDefs.TrimmingOrderList:
            icon = <ContentCut sx={sx} />;
            break;
        case screenDefs.CuringList:
        case screenDefs.Curing:
        case screenDefs.CuringActivity:
            icon = <ClockTimeTwoOutline sx={sx} />;
            break;
        case screenDefs.EmailTemplateList:
        case screenDefs.EmailTemplate:
            icon = <EmailTwoTone sx={sx} />;
            break;
        case screenDefs.EmailSentList:
        case screenDefs.EmailSent:
            icon = <DraftsTwoTone sx={sx} />;
            break;
        case screenDefs.RoomCleaningList:
        case screenDefs.RoomCleaning:
            icon = <RotateLeftRounded sx={sx} />;
            break;
        // case screenDefs.capaAction:
        //     icon = < sx={sx} />;
        //     break;
        case screenDefs.ReceiptList:
        case screenDefs.Receipt:
            icon = <ReceiptTwoTone sx={sx} />;
            break;
        case screenDefs.PurchaseOrderList:
        case screenDefs.PurchaseOrder:
            icon = <ShoppingCartTwoTone sx={sx} />;
            break;
        case screenDefs.Countries:
            icon = <PublicTwoTone sx={sx} />;
            break;
        case screenDefs.Provinces:
            icon = <MmsTwoTone sx={sx} />;
            break;
        // case screenDefs.managedDocumentChange:
        //     icon = < sx={sx} />;
        //     break;
        case screenDefs.ProductionBatches:
        case screenDefs.ProductionBatch:
            icon = <DvrTwoTone sx={sx} />;
            break;
        case screenDefs.CustomerComplaints:
        case screenDefs.CustomerComplaint:
            icon = <BugReportTwoTone sx={sx} />;
            break;
        case screenDefs.ManagedDocumentTemplates:
        case screenDefs.ManagedDocumentTemplate:
            icon = <SubjectTwoTone sx={sx} />;
            break;
        case screenDefs.CorrectiveActionRequests:
        case screenDefs.CorrectiveActionRequest:
            icon = <Ticket sx={sx} />;
            break;
        case screenDefs.Screens:
        case screenDefs.Screen:
            icon = <ImportantDevicesTwoTone sx={sx} />;
            break;
        case screenDefs.ScreenDefinitions:
            icon = <DvrTwoTone sx={sx} />;
            break;
        case screenDefs.LogList:
            icon = <HistoryTwoTone sx={sx} />;
            break;
        case screenDefs.ApiLogs:
            icon = <Api sx={sx} />;
            break;
        case screenDefs.ItemList:
        case screenDefs.Item:
            icon = <FormatListBulletedType sx={sx} />;
            break;
        case screenDefs.ItemCategories:
            icon = <ViewColumnTwoTone sx={sx} />;
            break;
        case screenDefs.AdminSettings:
            icon = <TuneRounded sx={sx} />;
            break;
        case screenDefs.ValueTypes:
        case screenDefs.Values:
            icon = <InfoTwoTone sx={sx} />;
            break;
        case screenDefs.TenantReportList:
        case screenDefs.TenantReport:
            icon = <FileChart sx={sx} />;
            break;
        case screenDefs.ReportDefinitionList:
        case screenDefs.ReportDefinition:
            icon = <Ballot sx={sx} />;
            break;
        case screenDefs.SettingPrefix:
            icon = <VerifiedUserTwoTone sx={sx} />;
            break;
        case screenDefs.Management:
            icon = <EmoticonPoop sx={sx} />;
            break;
        case screenDefs.DataUpload:
            icon = <FileUpload sx={sx} />;
            break;
        case screenDefs.HeadersFooters:
        case screenDefs.HeadersFootersList:
            icon = <PageLayoutHeaderFooter sx={sx} />;
            break;
        case screenDefs.Objects:
            icon = <CubeScan sx={sx} />;
            break;
        case screenDefs.DataSync:
            icon = <RestorePageTwoTone sx={sx} />;
            break;
        case screenDefs.InventoryBreakdown:
            icon = <CalculatorVariant sx={sx} />;
            break;
        // case screenDefs.person:
        //     icon = < sx={sx} />;
        //     break;
        case screenDefs.MessageList:
        case screenDefs.Message:
            icon = <SendTwoToneIcon sx={sx} />;
            break;
        case screenDefs.Announcements:
        case screenDefs.Announcement:
            icon = <AnnouncementIcon sx={sx} />;
            break;
        case screenDefs.RoomFeeding:
        case screenDefs.RoomFeedings:
            icon = <RoomFeedingIcon sx={sx} />;
            break;
        case screenDefs.FeedingRecipes:
        case screenDefs.FeedingRecipe:
            icon = <FeedingRecipeIcon sx={sx} />;
            break;
        case screenDefs.PestScouting:
        case screenDefs.PestScoutingList:
            icon = <BugCheckIcon sx={sx} />
            break;
        case screenDefs.CCXPostings:
        case screenDefs.CCXPosting:
            icon = <CcxPostingIcon sx={sx} />
            break;
	    case screenDefs.ExciseAdjustmentList:
            icon = <ExAdjIcon sx={sx} />
            break;
        case screenDefs.PesticideRecipes:
        case screenDefs.PesticideRecipe:
            icon = <PesticideRecipeIcon sx={sx} />;
            break;
        case screenDefs.EquipmentCalibrationChecks:
        case screenDefs.EquipmentCalibrationCheck:
            icon = <EquipmentCalibrationCheckIcon sx={sx} />;
            break;
        case screenDefs.EquipmentCalibrationTypes:
        case screenDefs.EquipmentCalibrationType:
            icon = <EquipmentCalibrationTypeIcon sx={sx} />;
            break;
        case screenDefs.InvoiceList:
        case screenDefs.Invoice:
            icon = <InvoiceIcon sx={sx} />;
            break;
        case screenDefs.MasterCaseList:
        case screenDefs.MasterCase:
            icon = <MasterCaseIcon sx={sx} />;
            break;
	    case screenDefs.AvailableInventoryList:
            icon = <AvailableInventoryIcon sx={sx} />;
            break;
        case screenDefs.ExciseStampAudit:
            icon = <ExciseStampIcon sx={sx} />;
            break;
        case screenDefs.CapaList:
            icon = <CapaIcon sx={sx} />;
            break;
        case screenDefs.BatchActivities:
            icon = <BatchActivityIcon sx={sx} />;
            break;
        case screenDefs.CleaningSolutionRecipes:
        case screenDefs.CleaningSolutionRecipe:
            icon = <CleaningSolutionRecipeIcon sx={sx} />;
            break;
        case screenDefs.RiskAssessments:
        case screenDefs.RiskAssessment:
            icon = <RiskAssessmentIcon sx={sx} />;
            break;
        case screenDefs.PastInventoryList:
        case screenDefs.PastInventory:
            icon = <PastInventoryIcon sx={sx} />;
            break;
        case screenDefs.CannabisInventory:
        case screenDefs.FormEntry:
        case screenDefs.FormComponent:
        case screenDefs.SopReview:
        case screenDefs.Notifications:
        case screenDefs.UserProfile:
        case screenDefs.ReviewApprovalList:
        case screenDefs.NotListed:
        default:
            icon = <NotInterestedIcon />;
            break;
    }

    if(title != null && placement != null){
        return <Tooltip title={title} placement={placement}>{icon}</Tooltip>;
    }else{
        return icon;
    };
}
