import { SET_CURRENT_USER, RESET_AUTH, SET_CONSTANTS } from '../actions/types';
import isEmpty from '../is-empty';
import common from '../jsons/common.json';

const initialState = {
    isAuthenticated: false,
    user: {},
}

export default function authReducer(state = initialState, action){
    switch(action.type){
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload.user,
                referralLink: action.payload.referralLink
            }
        case SET_CONSTANTS: 
            return {
                ...state, 
                common: common,
                constants: action.payload.constants,
                screenDefs: action.payload.screenDefs
            }
        case RESET_AUTH: 
            return initialState;
        default:
            return state;
    }
}