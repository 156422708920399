import React, { Suspense, useEffect } from 'react';
import routes from './Routes';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from'react-redux';
import isEmpty from '../../is-empty';

export default React.memo(({ auth, permission, component: Component, 
                             dispatch, noPath, routeAuth, ...rest}) => {
  const errors = useSelector(state => state.errors.list);
  
  useEffect(() => {
    if(!isEmpty(errors)) {
      dispatch({ type: 'CLEAR_ERROR' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={routeProps => {
        const crumbs = routes
          .filter(({ path }) => routeProps.match.path.includes(path))
          .map(({ path, ...rest }) => ({
            path: Object.keys(routeProps.match.params).length
              ? Object.keys(routeProps.match.params).reduce(
                  (path, param) =>
                    path.replace(`:${param}`, routeProps.match.params[param]), path)
              : path,
            ...rest
          }));
        if(routeAuth != null && auth.isAuthenticated === routeAuth.required) {
          if(permission.read !== true){
            return <Redirect to={{pathname: "/home"}} />;
          }
          const navMenu = { ...rest.navMenu, crumbs}
          if(navMenu.category !== 'printView')
            dispatch({type: "SET_NAVMENU_LOCATION", payload: navMenu});
          dispatch({type: "RESET_OPTIONMENU", payload: null});
          return <Suspense fallback="loading"><Component permission={permission} {...routeProps} /></Suspense>
        }else if(routeAuth != null && !routeAuth.required) {
          return <Component permission={permission} {...routeProps} />
        }else if(noPath)  {
          return <Redirect to={{pathname: "/home", state: { referral: routeProps.location.pathname}}} />;
        }else {
          return <Redirect to={{pathname: "/login", state: { referral: routeProps.location.pathname}}} />
        }
      }}
    />
  );
});